<template>
  <div>
    <CursorPaginatedDatatable
      class="br-16 elevation-1"
      :headers="headers"
      :items="add_business_requests"
      :filters="filters"
      :total-results="total_results"
      :sort-by="sort_by"
      :next-page="cursors.next_page"
      :prev-page="cursors.prev_page"
      :next-page-sorting="cursors.next_page_sorting"
      :prev-page-sorting="cursors.prev_page_sorting"
      :datatable-options="_datatable_options"
      :update-options="set_datatable_options"
      :get-items="get_add_business_requests"
      :add-filter="set_filters"
      :reset-sorted-datatable="reset_datatable_with_sorting"
      :loading="is_loading_requests"
      :no-data-text="`No Add a Business requests match your search`"
      :datatable-class="`br-8`"
      @update-sort-by="(v) => (sort_by = v)"
      @click:row="
        (request) => {
          edited_request = { ...request };
          edit_request_dialog = true;
        }
      "
    >
      <template v-slot:top>
        <div
          class="
            d-flex
            flex-column flex-sm-row
            justify-space-between
            align-center
            br-16
            pa-4
          "
        >
          <v-text-field
            v-model="_filter_autocomplete"
            class="br-8 mr-md-4 mb-4 mb-md-0"
            placeholder="Search by name, business name or email"
            label="Search"
            :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 350px' : ''"
            prepend-inner-icon="search"
            hide-details
            outlined
            clearable
            dense
          />
          <v-select
            v-model="_filter_status"
            class="br-8"
            label="Status"
            :style="$vuetify.breakpoint.mdAndUp ? 'max-width: 150px' : ''"
            :items="statuses"
            item-text="text"
            item-value="value"
            hide-details
            clearable
            outlined
            dense
          />
          <v-spacer />
        </div>
      </template>
      <template v-slot:[`item.timestamp`]="{ item }">
        {{ moment(item.timestamp).format("DD/MM/YYYY, h:mm:ss a") }}
      </template>
      <template v-slot:[`item.is_directory_only`]="{ item }">
        {{ item.is_directory_only ? "Directory" : "Trade" }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-chip :color="status_map[item.status].color">
          <v-icon
            small
            left
            v-if="item.status !== AddABusinessStatus.IN_PROGRESS"
            color="white"
            >{{ status_map[item.status].icon }}</v-icon
          >
          <v-progress-circular
            v-else
            class="mr-2"
            size="16"
            color="white"
            width="2"
            indeterminate
          />
          <span class="text-capitalize white--text">
            {{ title_case(item.status) }}
          </span>
        </v-chip>
      </template>
      <template v-slot:[`item.shop_id`]="{ item }">
        <v-btn
          small
          icon
          :disabled="!item.shop_id"
          @click.stop="
            $router.push({
              name: 'admin-shops-id',
              params: { id: item.shop_id },
            })
          "
          ><v-icon>mdi-pencil-outline</v-icon></v-btn
        >
      </template>
    </CursorPaginatedDatatable>

    <AdminDialog
      v-if="edited_request"
      :width="800"
      :show_dialog.sync="edit_request_dialog"
      :header="`Sign Up Request - ${edited_request.name}`"
      @confirm="
        $router.push({
          name: 'admin-register-aab',
          query: { add_a_business_id: edited_request.id },
        })
      "
      @delete="reject_request_dialog = true"
      delete_text="Reject"
      :disabled="
        ![AddABusinessStatus.PENDING, AddABusinessStatus.REJECTED].includes(
          edited_request.status
        )
      "
      :disabled_delete="edited_request.status !== AddABusinessStatus.PENDING"
      confirm_text="Register shop"
      close_text="Close"
      confirm_color="green lighten-2"
      :loading="is_editing_request"
    >
      <template v-slot:[`content`]>
        <h3 class="mx-4 mb-4 text-h4 primary--text font-weight-bold">
          Business Service Type
        </h3>
        <div class="mx-4 mb-8">
          <h4 class="text-h4 font-weight-bold mb-4">
            What would you like to do?*
          </h4>
          <v-radio-group
            v-model="edited_request.is_directory_only"
            readonly
            mandatory
            hide-details
          >
            <v-radio
              :value="false"
              label="Set up an online shop and trade online."
            />
            <v-radio :value="true" label="List as directory-only" />
          </v-radio-group>
        </div>
        <div class="mx-4 mb-8">
          <h4 class="text-h4 font-weight-bold mb-4">
            What type of business are you?*
          </h4>
          <v-checkbox
            v-for="(type, index) in business_types"
            :key="`business_type_${index}`"
            v-model="edited_request.business_type"
            readonly
            :label="type"
            :value="type"
            hide-details
            multiple
          />
        </div>
        <div v-if="!edited_request.is_directory_only" class="mx-4 mb-8">
          <h4 class="text-h4 font-weight-bold mb-4">
            What services would you like to use on our platform?*
          </h4>
          <v-checkbox
            v-for="(service, index) in services"
            :key="`service_${index}`"
            v-model="edited_request.services"
            readonly
            :label="service"
            :value="service"
            hide-details
            multiple
          />
        </div>
        <h3 class="mx-4 mb-4 text-h4 primary--text font-weight-bold">
          Business Information
        </h3>
        <div class="mx-4">
          <h4 class="text-h4 font-weight-bold mb-4">Business Name*</h4>
          <v-text-field
            v-model.trim="edited_request.name"
            readonly
            class="sign-up-input"
            dense
            rounded
            outlined
            color="primary"
          />
        </div>
        <div class="mx-4">
          <h4 class="text-h4 font-weight-bold mb-4">Business Address*</h4>
          <v-text-field
            v-model.trim="edited_request.business_address"
            readonly
            class="sign-up-input"
            dense
            rounded
            outlined
            color="primary"
          />
        </div>
        <div v-if="!edited_request.is_directory_only" class="mx-4">
          <h3 class="mb-4 text-h4 primary--text font-weight-bold">
            Registered Company
            <span class="black--text">OR</span>
            Sole Trader Information
          </h3>
          <h4 class="text-h4 font-weight-bold mb-4">
            Company Name*/ Sole Trader Name*
          </h4>
          <v-text-field
            v-model.trim="edited_request.company_name"
            readonly
            class="sign-up-input"
            dense
            rounded
            outlined
            color="primary"
          />
          <h4 class="text-h4 font-weight-bold mb-4">Company Number*</h4>
          <v-text-field
            v-model.trim="edited_request.company_number"
            readonly
            class="sign-up-input remove-spinner"
            dense
            rounded
            outlined
            color="primary"
          />
          <h4 class="text-h4 font-weight-bold mb-4">Company Address 1*</h4>
          <v-text-field
            v-model.trim="edited_request.address_1"
            readonly
            class="sign-up-input"
            dense
            rounded
            outlined
            color="primary"
          />
          <h4 class="text-h4 font-weight-bold mb-4">Company Address 2</h4>
          <v-text-field
            v-model.trim="edited_request.address_2"
            readonly
            class="sign-up-input"
            dense
            rounded
            outlined
            color="primary"
          />
          <h4 class="text-h4 font-weight-bold mb-4">Company Address 3</h4>
          <v-text-field
            v-model.trim="edited_request.address_3"
            readonly
            class="sign-up-input"
            dense
            rounded
            outlined
            color="primary"
          />
          <div class="d-flex">
            <div class="flex-grow-1 mr-4">
              <h4 class="text-h4 font-weight-bold mb-4">Town*</h4>
              <v-text-field
                v-model.trim="edited_request.town"
                readonly
                class="sign-up-input"
                dense
                rounded
                outlined
                color="primary"
              />
            </div>
            <div class="flex-grow-1 ml-4">
              <h4 class="text-h4 font-weight-bold mb-4">Postcode*</h4>
              <v-text-field
                v-model.trim="edited_request.postcode"
                readonly
                class="sign-up-input"
                dense
                rounded
                outlined
                color="primary"
              />
            </div>
          </div>
        </div>
        <div class="mx-4">
          <h4 class="text-h4 font-weight-bold mb-4">First Name*</h4>
          <v-text-field
            v-model.trim="edited_request.first_name"
            readonly
            class="sign-up-input"
            dense
            rounded
            outlined
            color="primary"
          />
          <h4 class="text-h4 font-weight-bold mb-4">Last Name*</h4>
          <v-text-field
            v-model.trim="edited_request.last_name"
            readonly
            class="sign-up-input"
            dense
            rounded
            outlined
            color="primary"
          />
          <h4 class="text-h4 font-weight-bold mb-4">E-mail*</h4>
          <v-text-field
            v-model.trim="edited_request.email"
            readonly
            class="sign-up-input"
            dense
            rounded
            outlined
            color="primary"
          />
          <h4 class="text-h4 font-weight-bold mb-4">Phone Number*</h4>
          <v-text-field
            v-model="edited_request.phone_no"
            readonly
            class="sign-up-input remove-spinner"
            dense
            rounded
            min="0"
            type="number"
            outlined
            hide-spin-buttons
            color="primary"
            @keydown="handle_numeric_input($event)"
          />
        </div>
      </template>
    </AdminDialog>
    <AdminDialog
      v-if="reject_request_dialog"
      :show_dialog.sync="reject_request_dialog"
      :header="`Reject Request - ${edited_request.name}`"
      @confirm="reject_aab_request()"
      @close="rejection_reason = ''"
      :disabled="edited_request.status !== AddABusinessStatus.PENDING"
      confirm_text="Reject"
      close_text="Close"
      confirm_color="red lighten-2"
      :loading="is_editing_request"
    >
      <template v-slot:[`content`]>
        <p>
          Input a reason for rejection. This will send an email to the applicant
        </p>
        <v-textarea v-model="rejection_reason" label="Reason"> </v-textarea>
      </template>
    </AdminDialog>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";

import { AddABusinessStatus, AddABusinessStatusMap } from "@/const";
import { field_req, valid_phone, valid_email } from "@/utils/form_val_rules";
import { title_case } from "@/utils/utils";
import CursorPaginatedDatatable from "@/components/CursorPaginatedDatatable";
import AdminDialog from "@/components/base/AdminDialog";
import {
  get_add_a_business_by_brand,
  update_add_a_business_request,
  reject_add_a_business,
} from "@/requests";

function default_datatable_options(limit) {
  return {
    itemsLength: 0,
    itemsPerPage: limit,
    page: 1,
    pageCount: 0,
    pageStart: 0,
    pageStop: 0,
  };
}
function default_cursors(sorting) {
  return {
    next_page: null,
    prev_page: null,
    next_page_sorting: null,
    prev_page_sorting: sorting,
  };
}

export default {
  name: "AddABusinessRequests",
  components: {
    CursorPaginatedDatatable,
    AdminDialog,
  },
  data() {
    return {
      moment,
      title_case,
      field_req,
      valid_phone,
      valid_email,
      AddABusinessStatus,
      get_add_a_business_by_brand,
      add_business_requests: [],
      total_results: 0,
      is_loading_requests: false,
      sort_by: "timestamp",
      datatable_options: {},
      filters: {
        autocomplete: null,
        status: null,
        cursor: null,
        sorting_cursor: "timestamp__desc__",
        limit: 10,
      },
      cursors: {
        next_page: null,
        prev_page: null,
        next_page_sorting: null,
        prev_page_sorting: null,
      },
      autocomplete_timer: null,

      headers: [
        { text: "Business Name", value: "name" },
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Phone No.", value: "phone_no" },
        { text: "E-mail", value: "email" },
        { text: "Date", value: "timestamp" },
        {
          text: "Shop Type",
          value: "is_directory_only",
          align: "center",
          width: "150px",
        },
        { text: "Status", value: "status", width: "100px", align: "center" },
        { text: "Shop", value: "shop_id", width: "100px", align: "center" },
      ],
      statuses: Object.values(AddABusinessStatus).map((status) => ({
        text: title_case(status),
        value: status,
      })),
      status_map: AddABusinessStatusMap,

      is_editing_request: false,
      edited_request: null,
      edit_request_dialog: false,

      reject_request_dialog: false,
      rejection_reason: "",

      business_types: ["Retail", "Hospitality", "Other Professional Services"],
      services: [
        "(Retail) Collection / Delivery",
        "(Hospitality) Online Ordering Platform: Collection / Delivery / Table Ordering",
        "(Hospitality) Table Booking System",
      ],
    };
  },
  computed: {
    ...mapState("SuperAdminStore", ["selected_brand"]),
    _datatable_options: {
      get() {
        return (
          this.datatable_options ||
          default_datatable_options(this.filters.limit)
        );
      },
      set(v) {
        this.datatable_options = v;
      },
    },
    _filter_autocomplete: {
      get() {
        return this.filters.autocomplete;
      },
      set(v) {
        clearTimeout(this.autocomplete_timer);
        this.autocomplete_timer = setTimeout(() => {
          this.set_filters({
            autocomplete: v,
          });
          this.get_add_business_requests(true);
        }, 500);
      },
    },
    _filter_status: {
      get() {
        return this.filters.status;
      },
      set(v) {
        this.set_filters({ status: v });
        this.get_add_business_requests(true);
      },
    },
  },
  methods: {
    async get_add_business_requests(clean = false) {
      this.is_loading_requests = true;
      if (clean) {
        if (this.filters.sorting_cursor) {
          const [sorting_cursor_start, direction] =
            this.filters.sorting_cursor.split("__");
          const sorting_cursor = `${sorting_cursor_start}__${direction}__`;
          this.reset_datatable_with_sorting(sorting_cursor);
        } else {
          this.reset_datatable_with_sorting(null);
        }
      }
      try {
        const {
          data: {
            items,
            prev_page,
            next_page,
            prev_page_sorting,
            next_page_sorting,
            total_results,
          },
        } = await get_add_a_business_by_brand(
          this.selected_brand.id,
          this.filters
        );

        this.add_business_requests = items;
        this.total_results = total_results;
        this.set_cursors({
          prev_page,
          next_page,
          prev_page_sorting,
          next_page_sorting,
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.is_loading_requests = false;
      }
    },
    async update_add_business_request() {
      this.is_editing_request = true;
      try {
        const { data: add_a_business } = await update_add_a_business_request(
          this.edited_request.id,
          this.edited_request
        );
        this.update_add_business_request_in_list(add_a_business);
        this.edit_request_dialog = false;
      } catch (e) {
        console.error(e);
      } finally {
        this.is_editing_request = false;
      }
    },
    set_filters(filters) {
      this.filters = Object.assign(this.filters, filters);
    },
    set_cursors(cursors) {
      this.cursors = cursors;
    },
    set_datatable_options(options) {
      this._datatable_options = options;
    },
    reset_datatable_with_sorting(sorting) {
      this.cursors = {
        ...default_cursors(sorting),
      };
    },
    update_add_business_request_in_list(add_a_business) {
      this.add_business_requests = [
        ...this.add_business_requests.map((r) =>
          r.id !== add_a_business.id ? r : add_a_business
        ),
      ];
    },
    async reject_aab_request() {
      try {
        await reject_add_a_business(this.edited_request.id, {
          reason: this.rejection_reason,
        });
        this.reject_request_dialog = false;
        this.edit_request_dialog = false;
        this.rejection_reason = "";
        this.edited_request = null;
        this.get_add_business_requests();
      } catch (e) {
        console.error(e);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep table td {
  cursor: pointer;
}
</style>
