export const BrandZoneLabel = {
  AREA: "area",
  STREET: "street",
  TOWN: "town",
  VILLAGE: "village",
  LOCATION: "location",
  DISTRICT: "district",
  ZONE: "zone",
};

export const AddABusinessStatus = {
  PENDING: "pending",
  IN_PROGRESS: "in_progress",
  REJECTED: "rejected",
  COMPLETED: "completed",
};

export const AddABusinessStatusMap = {
  [AddABusinessStatus.PENDING]: {
    color: "orange lighten-2",
    icon: "mdi-clock-outline",
  },
  [AddABusinessStatus.IN_PROGRESS]: {
    color: "primary",
    icon: "mdi-clock-outline",
  },
  [AddABusinessStatus.REJECTED]: {
    color: "red lighten-2",
    icon: "mdi-close",
  },
  [AddABusinessStatus.COMPLETED]: {
    color: "green lighten-2",
    icon: "mdi-check",
  },
};
